.sw-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align:center;
    width: 100%;
    max-width:600px;
    height: 70px;
  }
  
  .sw-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin:0 auto;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
  }

  .sw-line{
   position:relative;
    border-bottom: 1px dashed #767676;
    top:15px;
    z-index: -1;
    width: 100%;
    max-width:450px;
    margin-left:80px;
  }

  .line-container{
    border-bottom: 1px dashed #767676;
    top:15px;
    width: 100%;
    max-width:600px;
    
  }
  
  .sw-active {
    background-color: #da5373;
    color: #fff;
  }

  .sw-fs{font-size:.8em;}
  .sw-t{height:35px; width:130px;  line-height: 1.1em;}

  @media only screen and (max-width: 420px) {
    .sw-fs{font-size:.7em;}
    .sw-line{margin-left:0px;}
    .sw-t{display:none;}

    .sw-progress-bar {
      height: 30px;
    }
}
  