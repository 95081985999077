
.primary-card {
    background: #d6eff8 !important
}

.page-history h5{
    margin-top:30px!important;
}



.iki-history-active .list-group-item{
    background-color:#e9ffef;
    border:1px solid #e9ffef;
    
}
.iki-history-inactive .list-group-item{
    background-color:#f7f7f7;
    font-size:.8rem;
}

.iki-history p, .iki-history h6{
   margin:0!important;
}

.iki-card-default{
    background-color:#349bc1; color:#fefefe; padding:3px 5px;
    font-size:.6rem; margin: 0 5px;
    border-radius:3px;
}