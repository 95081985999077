.padding{
    padding:5rem !important;
    margin-left:300px;
}
.card {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
} 

.section-payment-methods .card{
    border: none;
}

.iki-light{
    color:#f77964;
}

.iki-card-details p{
    line-height:1.2em;
    margin:0;
    padding:0;
    font-size:.9rem;
}

.iki-card-details {
    margin:0 0 25px 0;
}

.page-loading{
    color:#ddd;
}

.iki-contact-cards{
    justify-content: space-between;
    align-items: stretch;
    gap:10px;
    margin:3px 0;
}

.section-payment-methods .card-footer{
    background-color:transparent;
    border:none;
}

.section-payment-methods .card-header{
    border:none;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

  

 

.section-payment-methods{
    background-color: #fff;
    border-color: #c8ced3 !important;
    border-radius:3px;
}

.section-service, .section-payment-methods{
    background-color: #fff;
    border-color: #c8ced3 !important;
    border-radius:3px;
} 

.section-service{
    background-color: #eee;
    border-color: #c8ced3 !important;
    border-radius:3px;
} 



.page-checkout .section-service  h3{
    font-size:1.1rem;
    margin:0;
    color:#444;
}  


.form-control:focus {
    color: #5c6873;
    background-color: #fff;
    border-color: #c8ced3 !important;
    outline: 0;
    box-shadow: 0 0 0 #F44336;
}

.row{
    padding-bottom: 15px;
}

.row.iki-row{
    padding-bottom: 0!important;
}

.toast {
    --bs-toast-max-width: 650px;
}