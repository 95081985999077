.iki-hello{
    margin:10px 0 20px 0;
    color: #333;
}

.transcend-category {
    margin-left: 10px;
    margin-top: 20px;
    color: #920375;
    font-weight: bold;;
}

.sw-item{
    border-bottom:1px solid #eee;
}

.iki-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; 
    right:0;
    background-color: #fff;
    top:0;
}

.iki-account a {
    text-align:center;
}

.iki-account a:hover{
    color: #dc3545;
    background-color:#fff;
  }


.iki-sm-txt{
    text-align:center;
    font-size:small;
}

.iki-current-active{
    background-color:#e9ffef;
    text-align:center;
    font-size:1.2rem;
    border-radius:5px;
    padding:5px; margin-bottom:20px;
}

.iki-sm-txt-left{
    text-align:left;
    font-size:small;
    margin-bottom:10px;
    margin-top:20px;
    display:inline-block;
    padding:2px 10px;
    background-color:#f9f9f9;
}

.iki-sm-txt-right{
    text-align:right;
    font-size:small;
    display:inline-block;
}

.iki-login-options button{
    margin:5px 0;
    height:100%;
    border-radius:0;
}

.iki-login-options {
    margin: 0 1rem 0 0;
    padding: 0 1rem 0 1rem!important;
    height: 100%;
    border-radius: 0;
}

.page-estimate .iki-btn-group{
    border-bottom:1px dashed #ccc;
    padding-bottom:20px;
}

.iki-spinner{
    width: 6rem; 
    height: 6rem;
}

.iki-list-group{
    cursor: pointer;
    font-size:1rem;
    background-color:transparent!important;
    --bs-list-group-bg:transparent!important;
    --bs-list-group-border-width:0px!important;
}

.iki-list-group .list-group-item:hover, 
.iki-list-group .list-group-item h5:hover{
    background: #f9f9f9;
}

.iki-list-group .list-group-item:hover  i.iki-light{
    color: #b40d6b;
}


.iki-list-group .item-selected
{   background: #0d6efd!important;
    color:#fff!important;
}

.iki-list-group .list-group-item{
    background: #fff;
    color:#333;
    margin:2px;
}



.iki-list-group .list-group-item h5{
    font-size:1rem
}

.iki-btn-group{
    display:flex;
    flex-flow:row wrap;
    justify-content: space-between;
    align-items: stretch;
    gap:10px;
    margin:20px 0;
}

.page-history h5{
    margin-top:20px;
    margin-bottom:3px;
}

.toast {
    --bs-toast-max-width: 650px;
}


