@media print {
  body * {
     visibility: hidden;
  }
  .print-receipt, .print-receipt * {
     visibility: visible;
  }
  .print-receipt {
     position: absolute;
     left: 0;
     top: 0;
  }
}

@media (max-width: 575.98px) {
  .sw-p-bar, #basic-navbar-nav{
    display:none;
  }

}

.sw-r-p a{
  text-decoration:none; font-size:20px; padding:5px 15px; margin:0 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./switch-wifi-bg-image.jpg);
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: fixed;

}

.sw-receipt .list-group-item{
  border:none;
  border-bottom : 1px solid #ddd;
}

.sw-contact-form{
  background-color:#c31672;
  color:#fff;
}

.sw-checkout-r{
  background-color: #eee;
  border-radius:5px;
}

.btn-black {
  background-color: black;
  color: white; 
  border:none; border-radius: 0;
}
.btn-black:hover {
  background-color: #333;
  color: white;
}


.sw-ch-pr{border-bottom:1px  dashed #ccc;}

.page-sw p{
  border-left:5px solid #eee; padding-left:15px;
}

.page-sw h5{
  font-size:120%; font-weight:600;
}



.sw-contact-form input, .sw-contact-form textarea{
  border-radius:0;
}
#basic-navbar-nav{
  flex-basis: 100%;
  flex-grow: 1;
}

.page-sw h1 {
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 5px solid #c8ced3;
  background-color: #333333;
  color: #fff;
  padding: 5px 15px;
  display: inline-block;
}

.sw-ft-logo{
  margin:0 0 0 0!important;
}

.sw-ft-logo img{
  max-width:50px!important;
  /* margin-left:20px; */
}


.sw-crt-rw{
  display:flex; align-items:center; text-align:right; text-decoration:none;
}

.sw-crt-rw .sw-gr{
 flex-grow:1;
}

.btn-link{text-decoration:none; }

.section-free p{
  text-align:center;
}

.sw-logo{max-width:180px;}



.fac-loading{position:absolute; z-index:1; top:200px}


.sw-ft{
  /* border-top:1px solid #ddd; */
}
.sw-ft-bottom{
  font-size:.8rem; 
}
.sw-ft-bottom .mnu{
  /* font-size:14px;  */
  color:#fff;
}

.sw-ft-bottom .btn{
  font-size:14px; color:#fff;
}
.sw-ft-bottom a{
padding:0 5px; text-decoration:none; color:#fff; list-style-type: stye none; list-style-type:none;
}
.sw-ft-bottom li{
  padding:0 5px; text-decoration:none; color:#fff; list-style-type: stye none; list-style-type:none;
  }

  

.sw-ft img{
   height:auto; overflow:hidden; max-height: 45px;
}

.page-free{
  border:20px solid #222;
  background-color:#222;
  color:#fff;
}

.page-checkout{
  background-color:#fff;
  border-radius:5px;
}

.modal-header{
  border-bottom:none
}


.page-landing{
  max-width:720px; margin:0 auto
}

.page-landing .tab-content{
  background-color:transparent;
  border-radius:3px;
  border:0 solid #eee;
}

.iki-sw-footer{
  background-color:#333;
  color:#fff;
}

.iki-sw-footer-light{
  background-color:#fff;
  color:#333;
}

.iki-header{
  margin:0 0 15px 0;
}


.iki-form-desc{
  text-align:center!important;
}

.row.iki-landing-form{
    padding-bottom: 0px;
}

.row.iki-landing-form .iki-form-button, 
.row.iki-landing-form  .iki-row-item-button,
.iki-form-container-login .iki-row-item-button,
.page-landing .iki-form-container-login{
  margin-bottom: 0px;
}

.iki-choose-plan{
  margin:10px 0;
}

.iki-choose-plan button{
  margin:10px 0;
}

.iki-welcome-name{
  color:#74025d;
}

.iki-form-desc-a{
  text-align:center!important;
  margin:15px auto 15px auto;
}

.iki-active-plan{
  color:#fff;
  font-size:1rem;
  background-color:#c31672;
  padding:20px;
  border-radius:0;
}

.sw-btn{
  width:100% !important;
  padding:2rem 1rem;
}

.sw-btn-r{
  width:100% !important;
  padding:2rem 1rem;
  margin-right:10px!important;
}

.sw-btn-l{
  width:100% !important;
  padding:2rem 1rem;
  margin-left:10px!important;
}

.iki-header .me-auto{
  align-items:stretch;
  width:100%;
}

#basic-nav-dropdown{
  padding:5px 7px; color:#fff; border-radius: 3px;
  /* border-bottom:1px solid #c31672; */
}


.iki-header .container{
  padding:0;
  /* justify-content: stretch; */
}

.iki-header .dropdown-menu, .iki-header  .dropdown-menu[data-bs-popper]{
  right:0; left:unset;
}


.iki-header-nav{
  display:flex;
  width:100%;
  flex-flow:row wrap;
  justify-content: center;
  align-items: center;
  gap:10px;
}


.sw-current, .sw-current .list-group-item{
  background-color: transparent;
}

.iki-my-account{
  color:#fff; background-color:#c31672; 
  border:1px solid #c31672;
  border-radius:3px;
}

.iki-my-account:hover{
  background-color:#c31672; color:#fff; border:1px solid #c31672;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #6c757d;
  border-color: #6c757d;
  opacity: var(--bs-btn-disabled-opacity);
}

.sw-primary-card{
  font-size:10px;
  letter-spacing: 1px;
  position:absolute; top:-3px; right:0;
  background-color:#d84604; color:#fff; padding:2px 4px; line-height:9px;
}

.sw-copy{
  font-size:.9em;
}

.sw-refund-alert{
  background-color:#eee;
}

.iki-header-nav-left a{
  border-radius:0px;
}

.iki-home {
  color:#c31672 !important;
  border-bottom:1px solid #c31672;
  padding-left:0; padding-right: 0;
  margin-left:10px; margin-right:10px;
}

.navbar-expand .navbar-nav .iki-home {

  padding-left:0; padding-right: 0;

}

.iki-cart{
    color:#c31672 !important;
}

.iki-header-nav-left{
  display:flex;
  flex-flow:row wrap;
  justify-content: space-between;
  align-items: stretch;
  gap:10px;
}


.iki-active-plan .plan-title{
  color:#fff;
  font-weight:bold;
  font-size:2rem;
}



.page-estimate .card{
  border:none;
}

.form-control {
  border-radius: 0.25rem;
}

#root .navbar{
  background-color: transparent!important;
}

.iki-form-container{
  display:flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: space-between;
  align-items: stretch;
  gap: 30px;
  margin:12px 0;
}

.iki-form-container-login{
  flex-flow: row wrap;
  max-width:300px;
  justify-content: flex-start;
  align-content: space-between;
  align-items: stretch;
  gap: 30px;
  margin:20px auto;
}
.iki-row-item-a{
  flex-grow: 1
}
.iki-row-item-b{
  flex-grow: 6
}

.iki-row-item-button{
  width:100%;
  max-width:400px;
  margin:10px auto;
}

.iki-row-item-button button{
  width:100%;
}



.iki-header .navbar-toggler{
  border-radius:2px;
  border:none;
}

.dropdown-menu{
  border:0;background-color:#fff; color:#555; border-radius:0; margin-top:-1px!important;
  box-shadow: -4px 5px 4px 2px rgba(0, 0, 0, 0.2);
}

.dropdown-menu a{
  color:#c31672;
  padding-top:5px;
  font-weight:600;
  font-size:120%;
  padding:10px 20px;
}



.iki-account .dropdown-menu a:hover{
  color: #c31672;
  background-color:transparent;

}


.iki-landing-expires{
  padding:3px 5px;
  background-color:#c31672;
  border-radius:3px;
  font-size:.8rem;
}

.btn-fb button{
  height:40px;
  background-image: url(./fb.png);
  background-repeat: no-repeat;
  background-position: 12px 4px;
  border:1px solid #ddd;
  font-size: 14px;
  border-radius:4px;
  background-color:#fff;
  text-align:right;
  padding-right:10px;
}

.social-btns{
  max-width:203px;
}

.iki-reset-password .btn-link {
  text-decoration: none;
}

.iki-reset-password .btn-link:hover {
    background-color: transparent;
    text-decoration: underline;
}

.fb-button{
  width:auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-tabs{
  justify-content:center;
  border-bottom: 1px solid #b3d7f4;
}

.iki-logout{
  color:#74025d;
  background-color:transparent;
  border:none
}

.nav-tabs .nav-link.active{
  color:#fff;
  background-color:#309bf1
}

.nav-tabs .nav-item{
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #fff;
  --bs-nav-tabs-border-radius: 0.375rem;
}

GoogleLogin {
  width: 100%;
}

.my-modal .modal-dialog {
  max-width: 100%;
  width: 100%;
}

.my-modal .modal-content {
  height: 100%;
  max-height:100%;
  width:100%;
}

.modal-fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: none;
}

.modal-fullscreen .modal-content {
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  
}

.service-active {
  background: #d6eff8 !important;
  opacity: .5;
}

/* footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
} */

footer {
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-logo {
  height: 45px;
}

.sw-hd-2{
  background-color:#777;
  color:#fff!important;
  padding:5px 15px;
  border-radius:5px;
  text-align:center;
  border-bottom:5px #ddd solid;
}





